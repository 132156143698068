"use client";

import { breakpointDesktop } from "@moller/design-system";
import { styled } from "styled-components";
import { RangeFilterSection } from "./rangeFilterSection";
import { useFilters } from "./hooks/useFilters";
import DefaultCheckboxGroup from "./defaultCheckboxGroup";
import { TradeInCard } from "@/components/tradeIn/tradeInCard";
import { translateFilterLabels } from "@/utils/translateFilter";

const Wrapper = styled.div`
  padding: inherit;
  border: inherit;
  display: none;
  flex-direction: inherit;
  row-gap: var(--moller-spacing-l);
  height: fit-content;

  @media screen and (min-width: ${breakpointDesktop}) {
    display: inherit;
  }
`;

export const Filters = ({ departmentSlug }: { departmentSlug?: string }) => {
  const {
    brandsWithModels,
    toggleBrandsWithModels,
    fuelFilter,
    toggleFuelFilter,
    priceInput,
    setPriceInput,
    modelYearInput,
    setModelYearInput,
    mileageInput,
    setMileageInput,
    onClickSearchRangeFilter,
    equipmentFilter,
    toggleEquipmentFilter,
  } = useFilters();

  return (
    <Wrapper>
      <TradeInCard departmentSlug={departmentSlug} />
      {brandsWithModels && (
        <DefaultCheckboxGroup
          headingText={translateFilterLabels("BrandsWithModels")}
          checkboxFilters={brandsWithModels}
          toggleFilter={toggleBrandsWithModels}
        />
      )}

      <RangeFilterSection
        headingText={translateFilterLabels("Price")}
        startRangeLabel="Fra pris"
        endRangeLabel="Til pris"
        formatInputValueWithSpacing={true}
        filter={priceInput}
        updateFilter={setPriceInput}
        onSearch={onClickSearchRangeFilter}
      />

      <RangeFilterSection
        headingText={translateFilterLabels("ModelYear")}
        startRangeLabel="Fra år"
        endRangeLabel="Til år"
        formatInputValueWithSpacing={false}
        filter={modelYearInput}
        updateFilter={setModelYearInput}
        onSearch={onClickSearchRangeFilter}
      />

      <RangeFilterSection
        headingText={translateFilterLabels("Mileage")}
        startRangeLabel="Fra km"
        endRangeLabel="Til km"
        formatInputValueWithSpacing={true}
        filter={mileageInput}
        updateFilter={setMileageInput}
        onSearch={onClickSearchRangeFilter}
      />
      {fuelFilter && (
        <DefaultCheckboxGroup
          headingText={translateFilterLabels("Fuel")}
          checkboxFilters={fuelFilter}
          toggleFilter={toggleFuelFilter}
        />
      )}
      {equipmentFilter && (
        <DefaultCheckboxGroup
          headingText={translateFilterLabels("Equipment")}
          checkboxFilters={equipmentFilter}
          toggleFilter={toggleEquipmentFilter}
        />
      )}
    </Wrapper>
  );
};
