import {
  breakpointDesktop,
  GhostButton,
  TextField,
} from "@moller/design-system";
import { styled } from "styled-components";
import { useEffect, useState } from "react";
import { numberWithSpaces } from "../../utils/numberWithSpacing";
import { removeSpacingFromString } from "../../utils/removeSpacingFromString";
import { RangeFilter } from "./types/filters.types";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: var(--moller-spacing-xxs);
`;

const Heading = styled.h3`
  margin: 0 0 var(--moller-spacing-xxs) 0;

  font-size: var(--moller-type-small-body-font-size);
  font-weight: 700;
  line-height: 150%;
  display: none;

  @media screen and (min-width: ${breakpointDesktop}) {
    display: inherit;
  }
`;

const StyledTextField = styled(TextField)`
  min-width: 104px !important;

  label {
    pointer-events: none;
  }
`;

const StyledGhostButton = styled(GhostButton)`
  min-width: 68px !important;
  margin-top: var(--moller-spacing-xxs) !important;
`;

interface Props {
  headingText: string;
  startRangeLabel: string;
  endRangeLabel: string;
  filter: RangeFilter | undefined;
  updateFilter: (x: RangeFilter) => void;
  onSearch: () => void;
  formatInputValueWithSpacing: boolean;
}
export const RangeFilterSection = ({
  headingText,
  startRangeLabel,
  endRangeLabel,
  filter,
  updateFilter,
  onSearch,
  formatInputValueWithSpacing,
}: Props) => {
  const [startRangeFieldInput, setStartRangeFieldInput] = useState<string>(
    `${filter?.startRange}` || "",
  );
  const [endRangeFieldInput, setEndRangeFieldInput] = useState<string>(
    `${filter?.endRange}` || "",
  );

  useEffect(() => {
    setStartRangeFieldInput(`${filter?.startRange}` || "");
    setEndRangeFieldInput(`${filter?.endRange}` || "");
  }, [filter]);

  useEffect(() => {
    updateFilter({
      startRange: Number(removeSpacingFromString(startRangeFieldInput)),
      endRange: Number(removeSpacingFromString(endRangeFieldInput)),
    });
  }, [startRangeFieldInput, endRangeFieldInput, updateFilter]);

  const setInputValue = (fieldInput: string) => {
    const numberInput = fieldInput.replace(/[^0-9]/g, ""); // Remove non-number characters

    if (numberInput === "0") return "";

    return formatInputValueWithSpacing
      ? numberWithSpaces(numberInput)
      : numberInput;
  };

  return (
    <div>
      <Heading>{headingText}</Heading>
      <Wrapper>
        <StyledTextField
          value={setInputValue(startRangeFieldInput)}
          label={startRangeLabel}
          onChange={(e) => setStartRangeFieldInput(e.target.value)}
        />
        <StyledTextField
          value={setInputValue(endRangeFieldInput)}
          label={endRangeLabel}
          onChange={(e) => setEndRangeFieldInput(e.target.value)}
        />
        <StyledGhostButton onClick={onSearch}>Søk</StyledGhostButton>
      </Wrapper>
    </div>
  );
};
