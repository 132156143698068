"use client";

import { styled } from "styled-components";
import { breakpointTablet } from "@moller/design-system";
import { CampaignBadge } from "./campaignBadge";
import { CampaignContent } from "./campaingContent";
import { Image } from "../image";
import { isMobile } from "@/utils/isMobile";
import { DealerCampaign } from "./campaign.types";

const Wrapper = styled.div`
  display: flex;
  min-height: 350px;
  border-radius: var(--moller-radius-card);

  @media screen and (max-width: ${breakpointTablet}) {
    flex-direction: column;
    background-color: unset;
  }
`;

const ImageComponent = styled(Image)`
  border-top-left-radius: var(--moller-radius-card);
  border-bottom-left-radius: var(--moller-radius-card);

  @media screen and (max-width: ${breakpointTablet}) {
    border-top-right-radius: var(--moller-radius-card);
    border-bottom-left-radius: 0;
  }
`;

const TextContent = styled.div`
  padding: var(--moller-spacing-3xl);
  min-width: 300px;

  @media screen and (max-width: ${breakpointTablet}) {
    padding: var(--moller-spacing-m) var(--moller-spacing-l);
    margin: 0;
  }
`;

const MobileBadgeWrapper = styled.div`
  display: none;

  @media screen and (max-width: ${breakpointTablet}) {
    display: flex;
    z-index: 1;
    margin: calc(var(--moller-spacing-xxl) * -1) 0 0 var(--moller-spacing-l);
  }
`;

export const Campaign = ({
  campaign,
  departmentSlug,
}: {
  campaign: DealerCampaign;
  departmentSlug?: string;
}) => {
  return (
    <Wrapper>
      {isMobile ? (
        <ImageComponent
          image={campaign.image}
          imageSrc={campaign.horImageSrc}
          sanitySource="main"
          alt={campaign.imageAltText}
          width={348}
          height={191}
        />
      ) : (
        <ImageComponent
          image={campaign.image}
          imageSrc={campaign.vertImageSrc}
          sanitySource="main"
          alt={campaign.imageAltText}
          width={320}
          height={426}
        />
      )}
      <MobileBadgeWrapper>
        <CampaignBadge dealerGroupName={campaign.dealerGroupName} />
      </MobileBadgeWrapper>
      <TextContent>
        <CampaignContent
          campaign={campaign}
          hideBadgeOnMobile
          departmentSlug={departmentSlug}
        />
      </TextContent>
    </Wrapper>
  );
};
