import useSWRInfinite from "swr/infinite";
import { CarsResponse } from "../../data/bruktbilsalgBackend/types/car.types";
import { useEffect, useState } from "react";
import { getQueryParamsFromFilters } from "../filters/utils/queryParamFilterUtils";
import { useFilters } from "../filters/hooks/useFilters";
import { getDepartmentsBySlug } from "@/data/forhandler/departments";

const DEPARTMENTIDS_QUERY_PARAM = "DepartmentIds";

function getActiveDepartmentIdFilters(
  initialDepartmentIds: string[] | undefined,
  filterDepartmentIds: string[],
): string[] {
  if (!initialDepartmentIds?.length) return filterDepartmentIds;
  if (!filterDepartmentIds.length) return initialDepartmentIds ?? [];

  // Only allow further filtering from initialDepartmentIds if it's not empty
  return filterDepartmentIds.filter((depId) =>
    initialDepartmentIds.includes(depId),
  );
}

export const fetcher = async (url: string) => {
  const res = await fetch(url);
  if (!res.ok) {
    throw new Error(`An error occurred while fetching data from url: ${url}`);
  }

  return res.json();
};

export const useCarFetcher = (initialDepartmentIds?: string[]) => {
  const [carFilterQueryParams, setCarFilterQueryParams] = useState<string>("");
  const pageSize = 15;

  const {
    brandsWithModels,
    fuelFilter,
    priceRange,
    modelYearRange,
    mileageRange,
    departmentsFilter,
    equipmentFilter,
  } = useFilters();

  const activeDepartmentSlugs =
    departmentsFilter && departmentsFilter.length > 0
      ? departmentsFilter.filter((d) => d.isActive).map((d) => d.key)
      : [];
  const filterDepartmentIds =
    activeDepartmentSlugs.length > 0
      ? activeDepartmentSlugs
          .flatMap((slug) => getDepartmentsBySlug(slug))
          .map((dep) => dep.id)
      : [];
  const activeDepartmentIds = filterDepartmentIds
    ? getActiveDepartmentIdFilters(initialDepartmentIds, filterDepartmentIds)
    : [];

  const { data, error, isLoading, size, setSize } = useSWRInfinite<
    CarsResponse,
    Error
  >(
    (pageIndex) => {
      const departmentQuery =
        activeDepartmentIds
          ?.map((depId) => `&${DEPARTMENTIDS_QUERY_PARAM}=${depId}`)
          .join("") ?? "";

      return (
        `/api/cars?offset=${pageIndex * pageSize}&limit=${pageSize}` +
        carFilterQueryParams +
        departmentQuery
      );
    },
    fetcher,
    {
      revalidateFirstPage: false,
      parallel: true,
    },
  );

  // TODO: move out to separate hook?
  // Set active filters as query params in Url
  useEffect(() => {
    const carFilterQueryParams = getQueryParamsFromFilters(
      brandsWithModels,
      fuelFilter,
      priceRange,
      modelYearRange,
      mileageRange,
      departmentsFilter,
      equipmentFilter,
    );
    const urlQueryParams = carFilterQueryParams;
    const url = new URL(
      window.location.origin + window.location.pathname,
    ).toString();

    window.history.replaceState(
      window.history.state,
      "",
      `${url}${urlQueryParams.length > 0 ? `?${urlQueryParams}` : ""}`,
    );

    setCarFilterQueryParams(carFilterQueryParams);
  }, [
    brandsWithModels,
    fuelFilter,
    priceRange,
    modelYearRange,
    mileageRange,
    departmentsFilter,
    size,
    equipmentFilter,
  ]);

  const fetchMoreCars = data ? data[data.length - 1].cars.length > 0 : true;
  const cars = data?.map((carResponse) => carResponse.cars);
  const carsCount = data ? data[0].numCars : null;

  if (error) {
    throw Error(error.message);
  }

  return {
    cars: cars?.flat() ?? [],
    carsCount,
    error,
    isLoading,
    setSize,
    fetchMoreCars,
  };
};
