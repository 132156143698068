// Just setup to get some dummy data in the filter list
// some should maybe be enums or something in final usage?

export type RangeFilterQueryKeys = {
  priceStartRangeKey: string;
  priceEndRangeKey: string;
  modelYearStartRangeKey: string;
  modelYearEndRangeKey: string;
  mileageStartRangeKey: string;
  mileageEndRangeKey: string;
};

export type CheckboxQueryKeys = {
  brandsKey: string;
  modelsKey: string;
  fuelKey: string;
  equipmentKey: string;
};

export type StringQueryKeys = {
  departmentsKey: string;
};

export type FilterQueryKeys = RangeFilterQueryKeys &
  CheckboxQueryKeys &
  StringQueryKeys;

export const filterQueryKeys: FilterQueryKeys = {
  brandsKey: "brands",
  modelsKey: "models",
  fuelKey: "Fuels",
  priceStartRangeKey: "PriceStart",
  priceEndRangeKey: "PriceEnd",
  modelYearStartRangeKey: "ModelYearStart",
  modelYearEndRangeKey: "ModelYearEnd",
  mileageStartRangeKey: "MileageStart",
  mileageEndRangeKey: "MileageEnd",
  departmentsKey: "departments",
  equipmentKey: "equipment",
};
