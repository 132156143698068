import { useContext, useEffect, useState } from "react";
import { FilterContext } from "../filtersProvider";
import {
  BrandWithModelsFilter,
  CheckboxFilter,
  DepartmentsFilter,
  EquipmentFilter,
  FuelFilter,
  ModelFilter,
  RangeFilter,
} from "../types/filters.types";

export const useFilters = () => {
  const filterContext = useContext(FilterContext);

  const {
    brandsWithModels,
    setBrandsWithModels,
    fuelFilter,
    setFuelFilter,
    priceRange,
    setPriceRange,
    modelYearRange,
    setModelYearRange,
    mileageRange,
    setMileageRange,
    departmentsFilter,
    setDepartmentsFilter,
    equipmentFilter,
    setEquipmentFilter,
  } = filterContext;

  const initialRangeVal = {
    startRange: 0,
    endRange: 0,
  };

  // temporary storage for the range filter input values
  const [priceInput, setPriceInput] = useState<RangeFilter>(initialRangeVal);
  const [mileageInput, setMileageInput] =
    useState<RangeFilter>(initialRangeVal);
  const [modelYearInput, setModelYearInput] =
    useState<RangeFilter>(initialRangeVal);

  useEffect(() => {
    priceRange && setPriceInput(priceRange);
    mileageRange && setMileageInput(mileageRange);
    modelYearRange && setModelYearInput(modelYearRange);
  }, [priceRange, mileageRange, modelYearRange]);

  const toggleBrandsWithModels = (
    toggleBrandKey: string,
    toggleModelKey?: string,
  ) => {
    // loop through all the brands and find which brand (and model if any) we want to toggle on/off
    const updatedFilter = brandsWithModels?.map((brand) => {
      // user toggles a brand
      if (brand.key === toggleBrandKey && !toggleModelKey) {
        if (brand.isActive) {
          // User toggles brand off, then we also want to toggle off all models for that brand
          brand.models.map((m) => (m.isActive = false));
        }

        brand.isActive = !brand.isActive;
      }

      // user toggles a model
      if (toggleModelKey) {
        brand.models = getUpdatedModels(brand.models, toggleModelKey);
      }
      return brand;
    });
    updatedFilter && setBrandsWithModels(updatedFilter);
  };

  // helper to toggle off a model and return the updated list of models
  const getUpdatedModels = (models: ModelFilter[], toggleModelKey: string) =>
    models.map(
      (model) =>
        (model.key === toggleModelKey && {
          ...model,
          isActive: !model.isActive,
        }) || {
          ...model,
        },
    );

  const toggleFuelFilter = (toggleFuelKey: string) => {
    toggleDefaultCheckboxFilter(toggleFuelKey, "Fuel", fuelFilter);
  };

  const toggleDepartmentsFilter = (toggleDepartmentsKey: string) => {
    toggleDefaultCheckboxFilter(
      toggleDepartmentsKey,
      "Departments",
      departmentsFilter,
    );
  };

  const toggleEquipmentFilter = (toggleEquipmentKey: string) => {
    toggleDefaultCheckboxFilter(
      toggleEquipmentKey,
      "Equipment",
      equipmentFilter,
    );
  };

  // Toggle-function for all checkbox-filters except BrandWithModels
  const toggleDefaultCheckboxFilter = (
    toggleFilterKey: string,
    kind: Exclude<CheckboxFilter["kind"], BrandWithModelsFilter["kind"]>,
    filter: CheckboxFilter[] | undefined,
  ) => {
    const updatedFilter = filter?.map((f) => {
      if (f.key === toggleFilterKey) {
        f.isActive = !f.isActive;
      }
      return f;
    });
    if (!updatedFilter) return;

    switch (kind) {
      case "Fuel":
        return setFuelFilter(updatedFilter as FuelFilter[]);
      case "Departments":
        return setDepartmentsFilter(updatedFilter as DepartmentsFilter[]);
      case "Equipment":
        return setEquipmentFilter(updatedFilter as EquipmentFilter[]);
    }
  };

  const onClickSearchRangeFilter = () => {
    setPriceRange(priceInput);
    setModelYearRange(modelYearInput);
    setMileageRange(mileageInput);
  };

  const resetAllFilters = () => {
    brandsWithModels &&
      setBrandsWithModels(
        brandsWithModels.map((brand) => {
          brand.isActive = false;
          brand.models.map((model) => {
            model.isActive = false;
          });
          return brand;
        }),
      );

    fuelFilter &&
      setFuelFilter(
        fuelFilter.map((fuel) => {
          return { ...fuel, isActive: false };
        }),
      );

    departmentsFilter &&
      setDepartmentsFilter(
        departmentsFilter.map((dep) => {
          return { ...dep, isActive: false };
        }),
      );

    equipmentFilter &&
      setEquipmentFilter(
        equipmentFilter.map((equipment) => {
          return { ...equipment, isActive: false };
        }),
      );

    setPriceRange(undefined);
    setPriceInput(initialRangeVal);
    setMileageRange(undefined);
    setMileageInput(initialRangeVal);
    setModelYearRange(undefined);
    setModelYearInput(initialRangeVal);
  };

  return {
    priceRange,
    mileageRange,
    modelYearRange,
    brandsWithModels,
    toggleBrandsWithModels,
    fuelFilter,
    toggleFuelFilter,
    priceInput,
    setPriceInput,
    modelYearInput,
    setModelYearInput,
    mileageInput,
    setMileageInput,
    departmentsFilter,
    toggleDepartmentsFilter,
    onClickSearchRangeFilter,
    resetAllFilters,
    equipmentFilter,
    toggleEquipmentFilter,
  };
};
