"use client";

import { breakpointDesktop, FilterChip } from "@moller/design-system";
import { styled } from "styled-components";
import { Collapse } from "@/components/collapse";
import { useFilters } from "@/components/filters/hooks/useFilters";

const StyledCollapse = styled(Collapse)`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: ${breakpointDesktop}) {
    display: none;
  }
`;

const ChipsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: var(--moller-spacing-xs) var(--moller-spacing-s);
`;

export const DepartmentFilterDesktop = () => {
  const { departmentsFilter, toggleDepartmentsFilter } = useFilters();

  return (
    <StyledCollapse
      id="department-filter-chips-desktop"
      backgroundColorVariable="--moller-color-background"
      collapsedHeight="85"
      buttonLabel={{ expand: "Vis mer", collapse: "Vis mindre" }}
      showCollapsedOverlay={false}
    >
      <ChipsWrapper>
        {departmentsFilter?.map(
          (dep) =>
            dep.name && (
              <FilterChip
                key={dep.key}
                selected={dep.isActive}
                onClick={() => toggleDepartmentsFilter(dep.key)}
              >
                {dep.name}
              </FilterChip>
            ),
        )}
      </ChipsWrapper>
    </StyledCollapse>
  );
};
