"use client";
import {
  Description,
  LabelText,
  LinkCard,
  Subtitle,
} from "@moller/design-system";
import React, { FC } from "react";
import NextLink from "../nextLink";
import { styled } from "styled-components";
import defaultThumbnail from "@/public/images/defaultCar-thumbnail.jpg";
import CarProperties from "./carProperties";
import CarThumbnailImage from "./carThumbnailImage";
import DepartmentSection from "./departmentSection";
import { Car } from "@/data/bruktbilsalgBackend/types/car.types";
import { numberWithSpaces } from "@/utils/numberWithSpacing";
import { CampaignBadge } from "../campaign/campaignBadge";
import { useTradeInContext } from "@/components/tradeIn/tradeInProvider";
import { useDealerCampaign } from "../campaign/campaignProvider";

interface Props {
  car: Car;
  departmentSlug?: string;
}

const CardContent = styled.div`
  padding: var(--moller-spacing-xs) var(--moller-spacing-xs) 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;

const TitleWrapper = styled.div`
  h2 {
    font-weight: 400;
    font-size: var(--moller-typeface-size-m);
  }
`;

const StyledCard = styled(LinkCard)`
  width: 100%;
  padding: 0 0 var(--moller-spacing-s);
  overflow: hidden;
  border-radius: var(--moller-radius-card);
  background-color: var(--moller-color-surface);
  color: var(--moller-color-on-surface);
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const BottomContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Price = styled(LabelText)`
  font-size: var(--moller-typeface-size-m);
  font-weight: 600;
  text-align: end;
`;

const MinifiedTotalPrice = styled(Description)`
  font-size: var(--moller-type-small-description-font-size);
`;

const Prefix = styled.span`
  font-size: var(--moller-type-small-subtitle-font-size);
`;

export const CarCard: FC<Props> = ({ car, departmentSlug }) => {
  const thumbnailImage =
    car.carImages && car.carImages[0]
      ? car.carImages[0].thumbnailUrl
      : defaultThumbnail;

  const { dealerCampaign } = useDealerCampaign(car.departmentName);

  const { isTradeInPricingOn, tradeInPrice } = useTradeInContext();
  const showTradeInPrice = !departmentSlug && isTradeInPricingOn;
  const totalPriceString = `${numberWithSpaces(car.price)} kr`;
  const updatedPriceString = `${numberWithSpaces(car.price - tradeInPrice)} kr`;
  const getPriceText = () => {
    if (car.isLeasing) {
      return "Leasing";
    }
    if (showTradeInPrice) {
      return updatedPriceString;
    }
    return totalPriceString;
  };

  return (
    <StyledCard
      aria-label={`${car.brand} ${car.model} (${car.modelYear}), ${car.price}kr, ${car.mileage}km, ${car.fuel}. Selges hos ${car.departmentName}`}
      as={NextLink}
      href={
        departmentSlug
          ? `/forhandler/${departmentSlug}/bil/${car.carId}`
          : `/bil/${car.carId}`
      }
    >
      <CarThumbnailImage
        thumbnailImageUrl={thumbnailImage}
        isSold={car.isSold}
      />
      <CardContent>
        <DepartmentSection departmentName={car.departmentName} />
        <TitleWrapper>
          <Subtitle $as="h2">
            {car.brand} {car.model}
          </Subtitle>
        </TitleWrapper>
        <CarProperties car={car} />
        <BottomContent>
          <div>
            {showTradeInPrice && (
              <MinifiedTotalPrice>{`Totalpris: ${totalPriceString}`}</MinifiedTotalPrice>
            )}

            <Price>
              {showTradeInPrice && <Prefix>Din pris </Prefix>}
              {getPriceText()}
            </Price>
          </div>
          {dealerCampaign && (
            <CampaignBadge dealerGroupName={departmentSlug || ""} />
          )}
        </BottomContent>
      </CardContent>
    </StyledCard>
  );
};
