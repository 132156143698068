"use client";
import React, { createContext, useEffect, useState } from "react";
import {
  BrandWithModelsFilter,
  FuelFilter,
  RangeFilter,
  DepartmentsFilter,
  EquipmentFilter,
} from "./types/filters.types";
import {
  activateBrandsWithModelsFromQuery,
  activateDepartmentsFromQuery,
  activateEquipmentFromQuery,
  activateFuelFromQuery,
  setRangeFilterFromQuery,
} from "./utils/queryParamFilterUtils";
import { useSearchParams } from "next/navigation";

export interface CarFilterContext {
  brandsWithModels: BrandWithModelsFilter[] | undefined;
  setBrandsWithModels: (x: BrandWithModelsFilter[]) => void;
  fuelFilter: FuelFilter[] | undefined;
  setFuelFilter: (x: FuelFilter[]) => void;
  priceRange: RangeFilter | undefined;
  setPriceRange: (x: RangeFilter | undefined) => void;
  modelYearRange: RangeFilter | undefined;
  setModelYearRange: (x: RangeFilter | undefined) => void;
  mileageRange: RangeFilter | undefined;
  setMileageRange: (x: RangeFilter | undefined) => void;
  departmentsFilter: DepartmentsFilter[] | undefined;
  setDepartmentsFilter: (x: DepartmentsFilter[]) => void;
  equipmentFilter: EquipmentFilter[] | undefined;
  setEquipmentFilter: (x: EquipmentFilter[]) => void;
}

const carFilterContextDefaultVal: CarFilterContext = {
  brandsWithModels: [],
  setBrandsWithModels: () => {},
  fuelFilter: [],
  setFuelFilter: () => {},
  priceRange: undefined,
  setPriceRange: (x: RangeFilter | undefined) => {},
  modelYearRange: undefined,
  setModelYearRange: (x: RangeFilter | undefined) => {},
  mileageRange: undefined,
  setMileageRange: (x: RangeFilter | undefined) => {},
  departmentsFilter: [],
  setDepartmentsFilter: () => {},
  equipmentFilter: [],
  setEquipmentFilter: () => {},
};

export const FilterContext = createContext(carFilterContextDefaultVal);

interface FiltersProvider {
  fetchedBrandsWithModels: BrandWithModelsFilter[];
  fetchedFuels: FuelFilter[];
  fetchedEquipment: EquipmentFilter[];
  departments?: DepartmentsFilter[];
  children: React.ReactNode;
}
export const FiltersProvider = ({
  fetchedBrandsWithModels,
  fetchedFuels,
  fetchedEquipment,
  departments,
  children,
}: FiltersProvider) => {
  const [brandsWithModels, setBrandsWithModels] = useState<
    BrandWithModelsFilter[]
  >(fetchedBrandsWithModels);
  const [fuelFilter, setFuelFilter] = useState<FuelFilter[]>(fetchedFuels);
  const [priceRange, setPriceRange] = useState<RangeFilter>();
  const [modelYearRange, setModelYearRange] = useState<RangeFilter>();
  const [mileageRange, setMileageRange] = useState<RangeFilter>();
  const [departmentsFilter, setDepartmentsFilter] = useState<
    DepartmentsFilter[]
  >(departments || []);
  const [equipmentFilter, setEquipmentFilter] =
    useState<EquipmentFilter[]>(fetchedEquipment);

  const searchParams = useSearchParams();

  const activateFiltersFromSearchParams = () => {
    setBrandsWithModels((b) =>
      activateBrandsWithModelsFromQuery(b, searchParams),
    );
    setFuelFilter((f) => activateFuelFromQuery(f, searchParams));

    setEquipmentFilter((f) => activateEquipmentFromQuery(f, searchParams));

    setPriceRange(
      setRangeFilterFromQuery(
        "priceStartRangeKey",
        "priceEndRangeKey",
        searchParams,
      ),
    );

    setModelYearRange(
      setRangeFilterFromQuery(
        "modelYearStartRangeKey",
        "modelYearEndRangeKey",
        searchParams,
      ),
    );

    setMileageRange(
      setRangeFilterFromQuery(
        "mileageStartRangeKey",
        "mileageEndRangeKey",
        searchParams,
      ),
    );

    setDepartmentsFilter((f) => activateDepartmentsFromQuery(f, searchParams));
  };

  useEffect(() => {
    activateFiltersFromSearchParams();
  }, [searchParams]);

  return (
    <FilterContext.Provider
      value={{
        brandsWithModels,
        setBrandsWithModels,
        fuelFilter,
        setFuelFilter,
        priceRange,
        setPriceRange,
        modelYearRange,
        setModelYearRange,
        mileageRange,
        setMileageRange,
        departmentsFilter,
        setDepartmentsFilter,
        equipmentFilter,
        setEquipmentFilter,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};
